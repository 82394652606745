import Vue from 'vue'
import authenticated from '@/router/guards/authenticated'
import guest from '@/router/guards/guest'
import setup from '@/router/guards/is-setup'
import accountHolder from '@/router/guards/is-account-holder'
import EasyRoute from '@billow/easy-route'
import VueBulma from '@billow/vue-bulma'
import { filters } from '@billow/js-helpers'
import * as ModalDialogs from 'vue-modal-dialogs'
import Toast from '@billow/toast'
import './layout/install'
import './components/install'
import './pages/checklist/partials/install'
import './pages/reporting/builder/install'
import './pages/reporting/widgets/install'
import './pages/reporting/charts/install'
import VueApexCharts from 'vue-apexcharts'
import VTooltip from 'v-tooltip'
import GmapVue from 'gmap-vue'
import { mapGetters } from 'vuex'

export const boot = (options) => {
  Vue.use(filters)
  Vue.use(VueBulma, { store: options.store })
  Vue.use(EasyRoute, { router: options.router, guards: [authenticated, guest, setup, accountHolder] })
  Vue.use(ModalDialogs)
  Vue.use(Toast, { types: Toast.types, namespaced: true })
  Vue.use(VueApexCharts)
  Vue.component('apexchart', VueApexCharts)
  Vue.use(VTooltip)
    Vue.use(GmapVue, {
      load: {
        key: process.env.VUE_APP_GOOGLE_PLACES_KEY,
        libraries: 'places',
      },
      autoBindAllEvents: true,
      installComponents: true,
      dynamicLoad: false,
    })
  
}

export const startVue = (options) => {
  new Vue({
    
    store: options.store,
    
    router: options.router,
    
    render: h => h(options.App),

    methods: {
      can(_permission) {
        if(this.isAdmin) {
          return true
        }
        return this.user.abilities.filter(permission => permission.name === _permission).length > 0
      },
      cant(_permission) {
        if(this.isAdmin) {
          return false
        }
        return this.user.abilities.filter(permission => permission.name === _permission).length === 0
      },
      isAdmin() {
        return this.user.abilities.filter(permission => permission.name === '*').length > 0
      },
      isSuper() {
        return this.user.id === this.subscription.account.user_id
      },
      isPassiveUser() {
        return this.user.is_passive
      }
    },

    computed: {
      ...mapGetters('auth', [
        'user'
      ]),
      ...mapGetters('billing', [
        'subscription'
      ]),
      errors() {
        return options.store.getters['global/errors'].errors
      }
    }

  }).$mount('#app')
}