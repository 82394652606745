import moment from 'moment'
import WorkOrderClassification from './WorkOrderClassification'
import Location from './Location'
import Customer from './Customer'

export default class WorkOrder
{
  constructor(workOrder = {}) {
    this.id = workOrder.id || null
    this.uuid = workOrder.uuid || null
    this.number = workOrder.number || ''
    this.reference = workOrder.reference || ''
    this.brief = workOrder.brief || ''
    this.starting_at = workOrder.starting_at || moment().format()
    this.ending_at = workOrder.ending_at || moment().format()
    this.closed_off_at = workOrder.closed_off_at || ''
    this.status = workOrder.status || ''
    this.location_id = workOrder.location_id || ''
    this.location_uuid = workOrder.location_uuid || ''
    this.location = workOrder.location || new Location()
    this.customer_id = workOrder.customer_id || ''
    this.customer_uuid = workOrder.customer_uuid || ''
    this.customer = workOrder.customer || new Customer()
    this.classification_id = workOrder.classification_id || ''
    this.classification_uuid = workOrder.classification_uuid || ''
    this.classification = workOrder.classification || new WorkOrderClassification()
    this.priority_id = workOrder.priority_id || ''
    this.team_id = workOrder.team_id || ''
    this.team_uuid = workOrder.team_uuid || ''
    this.created_at = workOrder.created_at || ''
    this.updated_at = workOrder.updated_at || ''
    this.team = workOrder.team || {}
    this.priority = workOrder.priority || {}
    this.creator = workOrder.creator || {}
    this.inspections = workOrder.inspections || []
    this.signatures = workOrder.signatures || []
    this.internal = workOrder.internal || false
  }

  static mutations() {
    return {
      setWorkOrder: (state, payload) => state.workOrder = new WorkOrder(payload),
      clearWorkOrder: (state) => state.workOrder = new WorkOrder(),
      updateReference: (state, payload) => state.workOrder.reference = payload,
      updateBrief: (state, payload) => state.workOrder.brief = payload,
      updateStartingDate: (state, payload) => state.workOrder.starting_at = payload,
      updateEndDate: (state, payload) => state.workOrder.ending_at = payload,
      updateLocation: (state, payload) => state.workOrder.location_uuid = payload,
      updateCustomer: (state, payload) => state.workOrder.customer_uuid = payload,
      updateClassification: (state, payload) => state.workOrder.classification_uuid = payload,
      updatePriority: (state, payload) => state.workOrder.priority_id = payload,
      updateTeam: (state, payload) => state.workOrder.team_uuid = payload,
      updateStatus: (state, payload) => state.workOrder.status = payload,
      setInspections: (state, payload) => state.workOrder.inspections = payload,
      toggleInternal: (state) => state.workOrder.internal = !state.workOrder.internal,
      populateFromTemplate: (state, payload) => {
        state.workOrder.reference = payload.reference
        state.workOrder.brief = payload.brief
        state.workOrder.location_id = payload.location_id
        state.workOrder.location_uuid = payload.location.uuid
        state.workOrder.customer_id = payload.customer_id
        state.workOrder.customer_uuid = payload.customer.uuid
        state.workOrder.classification_id = payload.classification_id
        state.workOrder.classification_uuid = payload.classification.uuid
        state.workOrder.priority_id = payload.priority_id
        state.workOrder.team_uuid = payload.team.uuid
        state.workOrder.team_id = payload.team_id
      }
    }
  }
}