<template>
<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <router-link class="navbar-item" :to="{ name: 'dashboard' }">
      <img class="logo" src="/img/inspeckta-logo-white.svg" width="100" height="100" alt="inspeckta-logo">
    </router-link>

    <a role="button" class="navbar-burger" :class="{ 'is-active': is_open }" @click="is_open = !is_open" aria-label="menu" aria-expanded="false" data-target="mainNavigation">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="mainNavigation" class="navbar-menu" :class="{ 'is-active': is_open }">
    <div class="navbar-start is-hidden-mobile">
      <div class="navbar-item" v-if="plan.title === 'Free' || subscription.on_trial">
        <span class="tag ml-5" :class="{
          'is-warning': subscription.on_trial || plan.title === 'Free',
        }">{{ plan.title }}{{ subscription.on_trial && plan.title !== 'Free' ? ' Trial' : '' }}</span>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item is-hidden-mobile">
        <router-link :to="{
          name: 'notifications'
        }">
          <span class="is-flex tag is-dark">
            <span class="has-text-weight-light">{{ notifications_count }}</span>
            <icon icon="bell" type="far" class="ml-1" />
          </span>
        </router-link>
      </div>
      <dropdown-wrapper class="navbar-item is-hidden-mobile" position="bottom-end">
        <div
          class="cursor-pointer is-flex is-align-items-center"
          slot="trigger">
          <avatar 
            :path="user.profile.picture_url"
            :name="user.name"
            :size="38"
          />
          <p class="ml-3">{{ user.full_name }}</p>

          <icon class="ml-1" icon="chevron-down"/>
        </div>

        <dropdown-item @click="goToProfile">
          <icon class="mr" icon="user" type="fas" />
          <span>Profile</span>
        </dropdown-item>

        <dropdown-item v-if="isAccountHolder" @click="goToAccount">
          <icon class="mr" icon="building-flag" />
          <span>Account</span>
        </dropdown-item>

        <dropdown-item @click="goToSupport">
          <icon class="mr" icon="life-ring" type="far"/>
          <span>Support</span>
        </dropdown-item>
        
        <dropdown-item @click="logout">
          <icon class="mr" icon="arrow-right-from-bracket"/>
          <span>Logout</span>
        </dropdown-item>
        <hr class="dropdown-divider">
        <dropdown-item>
          <p>{{ subscription.account.company_name }}</p>
          <p>{{ user.email }}</p>
          <p class="has-text-weight-bold mt-1">v{{ version }}</p>
        </dropdown-item>
      </dropdown-wrapper>

      <!-- Mobile Only -->
      <router-link @click.native="is_open = false" :class="navbarItemClasses" exact :to="{ name: 'dashboard' }">
        <icon icon="house-chimney-crack" class="mr-4" />
        <span class="item-name">Dashboard</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" exact :to="{ name: 'work-orders' }">
        <icon icon="helmet-safety" class="mr-4" />
        <span class="item-name">Work Orders</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" exact :to="{ name: 'checklists' }">
        <icon icon="list-check" class="mr-4" />
        <span class="item-name">Checklists</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" exact :to="{ name: 'equipment' }">
        <icon icon="hashtag" class="mr-4" />
        <span class="item-name">Equipment</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'customers' }">
        <icon icon="people-group" class="mr-4" />
        <span class="item-name">Customers</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'locations' }">
        <icon icon="map-location-dot" class="mr-4" />
        <span class="item-name">Locations</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'branches' }">
        <icon icon="building-flag" class="mr-4" />
        <span class="item-name">Branches</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'teams' }">
        <icon icon="people-arrows" class="mr-4" />
        <span class="item-name">Teams</span>
      </router-link>
      
      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'users' }">
        <icon icon="street-view" class="mr-4" />
        <span class="item-name">Users</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'checklist-classifications' }">
        <span class="item-name">Checklist Classifications</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'equipment-classifications' }">
        <span class="item-name">Equipment Classifications</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'work-order-classifications' }">
        <span class="item-name">Work Order Classifications</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'checklist-templates' }">
        <icon icon="clipboard-list" class="mr-4" />
        <span class="item-name">Checklist Templates</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'work-order-templates' }">
        <icon icon="helmet-safety" class="mr-4" />
        <span class="item-name">Work Order Templates</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'checkpoints' }">
        <icon icon="list-ol" class="mr-4" />
        <span class="item-name">Checkpoints</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" class="is-flex is-align-items-center" :to="{ name: 'reports' }">
        <icon icon="chart-gantt" class="mr-4" />
        <span class="item-name">Reporting</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'alerts' }">
        <icon icon="bell" type="far" class="mr41" />
        <span class="item-name">Alerts</span>
      </router-link>

      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'profile' }">
        <icon class="mr" icon="user" type="fas" />
        <span>Profile</span>
      </router-link>
      
      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'account' }">
        <icon class="mr" icon="building-flag" />
        <span>Account</span>
      </router-link>
      
      <router-link @click.native="is_open = false" :class="navbarItemClasses" :to="{ name: 'support' }">
        <icon class="mr" icon="life-ring" type="far" />
        <span>Support</span>
      </router-link>

      <div @click="logout" :class="navbarItemClasses">
        <icon class="mr" icon="arrow-right-from-bracket" />
        <span>Logout</span>
      </div>
    </div>
  </div>
</nav>
</template>
<script>
import { mapGetters } from 'vuex'
import { auth as backend } from '@/api'
var pjson = require('../../../package.json')

export default {

  data: () => ({
    notifications_count: 0,
    is_open: false
  }),

  created() {
    backend.loadUnreadNotificationsCount(({data}) => {
      this.notifications_count = data
    }, () => {})
  },

  methods: {
    goToAccount() {
      this.$router.push({
        name: 'account'
      })
    },
    goToProfile() {
      this.$router.push({
        name: 'profile'
      })
    },
    goToSupport() {
      this.$router.push({
        name: 'support'
      })
    },
    logout() {
      this.$store.commit('auth/deleteToken')
      this.$store.commit('auth/clearUser')
      this.$router.push({
        name: 'signin'
      })
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('billing', [
      'subscription'
    ]),
    plan() {
      return this.subscription.price.plan
    },
    navbarItemClasses() {
      return 'navbar-item is-flex is-align-items-center is-hidden-tablet'
    },
    version() {
      return pjson.version
    },
    isAccountHolder() {
      return this.user.id === this.subscription.account.user_id
    }
  },

}
</script>