import { services } from '@billow/js-helpers'

let guard = () => new Promise((resolve, reject) => {

  if (!services.auth.token()) {
    resolve()
  } else {
    reject({
      name: 'dashboard',
    })
  }
})

export default {
  name: 'guest',
  run: guard
}
