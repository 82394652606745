import Vue from 'vue'
import VueRouter from 'vue-router'
import { concat } from 'lodash'

Vue.use(VueRouter)

import guest from './guest'
import main from './main'
import setup from './setup'

export default new VueRouter({

  mode: 'history',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  base: process.env.BASE_URL,

  routes: concat(
    guest,
    main,
    setup
  )

})