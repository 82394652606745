import Address from "./Address"

export default class Location
{

  constructor(location = {}) {
    this.id = location.id || null
    this.uuid = location.uuid || ''
    this.name = location.name || ''
    this.legal_entity = location.legal_entity || ''
    this.customer_id = location.customer_id || ''
    this.customer_uuid = location.customer_uuid || ''
    this.reference = location.reference || ''
    this.type = location.type || ''
    this.branches = location.branches || []
    this.equipment_count = location.equipment_count || 0
    this.work_orders_count = location.work_orders_count || 0
    this.address = location.address ? new Address(location.address) : new Address()
  }

  static mutations() {
    return {
      setLocation: (state, payload) => state.location = new Location(payload),
      clearLocation: (state) => state.location = new Location(),
      clearAddress: (state) => state.location.address = new Address(),
      updateName: (state, payload) => state.location.name = payload,
      updateLegalEntity: (state, payload) => state.location.legal_entity = payload,
      updateReference: (state, payload) => state.location.reference = payload,
      updateLocationReference: (state, payload) => state.location.reference = payload,
      updateCustomer: (state, payload) => state.location.customer_uuid = payload,
      updateLocationType: (state, payload) => state.location.type = payload,
      updateLocationBranches: (state, payload) => state.location.branches = payload,
      updateStreet: (state, payload) => state.location.address.street = payload,
      updateCity: (state, payload) => state.location.address.city = payload,
      updateSuburb: (state, payload) => state.location.address.suburb = payload,
      updatePostCode: (state, payload) => state.location.address.post_code = payload,
      updateTimezone: (state, payload) => state.location.address.timezone = payload,
      updateLatitude: (state, payload) => state.location.address.lat = payload,
      updateLongitude: (state, payload) => state.location.address.lng = payload,
      updateCountry: (state, payload) => state.location.address.country_id = payload,
      updateProvince: (state, payload) => state.location.address.province = payload,
      updateType: (state, payload) => state.location.type = payload,
      updateLocationName: (state, payload) => state.location.name = payload,
      updateLocationLegalName: (state, payload) => state.location.legal_entity = payload,
      updateBranches: (state, payload) => state.location.branches = payload,
      updateAddress: (state, payload) => {
        state.location.address = payload

        state.location.address.display_text = [
          state.location.address.street,
          state.location.address.suburb,
          state.location.address.city,
          state.location.address.country,
        ].reduce((string, item) => `${string}${item}, `, '')

        state.location.address.display_text = state.location.address.display_text.substring(0, state.location.address.display_text.length - 2)
      }
    }
  }

}