import WorkOrderClassification from '../models/WorkOrderClassification'
import { workOrderClassification as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    work_order_classification: new WorkOrderClassification(),
    work_order_classifications: []
  },

  mutations: {
    ...WorkOrderClassification.mutations(),
    setWorkOrderClassifications: (state, payload) => state.work_order_classifications = payload
  },

  actions: {
    create: ({state}) => new Promise((resolve, reject) => {
      const classification = { ...state.work_order_classification }
      classification.segmented_teams = classification.segmented_teams.map(team => team.uuid)
      backend.create(classification, resolve, reject)
    }),
    update: ({state}) => new Promise((resolve, reject) => {
      const classification = { ...state.work_order_classification }
      classification.segmented_teams = classification.segmented_teams.map(team => team.uuid)
      backend.update(classification, resolve, reject)
    }),
    load: ({commit}, classificationId) => new Promise((resolve, reject) => {
      backend.load(classificationId, ({data}) => {
        commit('setClassification', data)
        resolve()
      }, reject)
    }),
    loadWorkOrderClassificationList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadWorkOrderClassificationList(({data}) => {
        commit('setWorkOrderClassifications', data)
        resolve()
      }, reject)
    }),
    deleteClassification: ({state}) => new Promise((resolve, reject) => {
      backend.deleteClassification(state.work_order_classification.uuid, resolve, reject)
    })
  },

  getters: {
    work_order_classification: state => state.work_order_classification,
    work_order_classifications: state => state.work_order_classifications,
  }

}