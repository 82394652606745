export default class Branch
{
  constructor(branch = {}) {
    this.id= branch.id || null
    this.uuid= branch.uuid || ''
    this.branch_email = branch.email || ''
    this.contact_number= branch.contact_number || ''
    this.name= branch.name || null
    this.address= branch.address || {}
    this.users = branch.users || []
    this.branch_manager_id = branch.branch_manager_id || ''
    this.branch_manager_uuid = branch.branch_manager_uuid || ''
  }

  static mutations() {
    return {
      setBranch: (state, payload) => state.branch = new Branch(payload),
      clearBranch: (state) => state.branch = new Branch(),
      updateName: (state, payload) => state.branch.name = payload,
      updateUsers: (state, payload) => state.branch.users = payload,
      updateEmail: (state, payload) => state.branch.branch_email = payload,
      updateContactNumber: (state, payload) => state.branch.contact_number = payload,
      updateBranchManager: (state, payload) => state.branch.branch_manager_uuid = payload,
    }
  }
}