import moment from 'moment'
import WorkOrderClassification from './WorkOrderClassification'
import Location from './Location'
import Customer from './Customer'
import Team from './Team'

export default class WorkOrderTemplate {

    constructor(workOrder = {}) {
        this.id = workOrder.id || null
        this.name = workOrder.name || ''
        this.reference = workOrder.reference || ''
        this.brief = workOrder.brief || ''
        this.starting_at = workOrder.starting_at || moment().format()
        this.ending_at = workOrder.ending_at || moment().format()
        this.closed_off_at = workOrder.closed_off_at || ''
        this.status = workOrder.status || ''
        this.location_id = workOrder.location_id || ''
        this.location = workOrder.location || new Location()
        this.customer_id = workOrder.customer_id || ''
        this.customer = workOrder.customer || new Customer()
        this.classification_id = workOrder.classification_id || ''
        this.classification = workOrder.classification || new WorkOrderClassification()
        this.priority_id = workOrder.priority_id || ''
        this.team_id = workOrder.team_id || ''
        this.first_run = workOrder.first_run || ''
        this.opening_cutoff_time = workOrder.opening_cutoff_time || ''
        this.schedules_count = workOrder.schedules_count || 0
        this.team = workOrder.team || new Team()
        this.schedule = workOrder.schedule || {
            interval: '',
            time_of_day: '',
            day_of_week: '',
            day_of_month: '',
            weekends: 'open_on_weekend',
            holidays: 'open_on_holiday',
        }
    }

    static mutations() {
        return {
            setWorkOrder: (state, payload) => state.template = new WorkOrderTemplate(payload),
            clearWorkOrder: (state) => state.template = new WorkOrderTemplate(),
            updateReference: (state, payload) => state.template.reference = payload,
            updateBrief: (state, payload) => state.template.brief = payload,
            updateStartingDate: (state, payload) => state.template.starting_at = payload,
            updateEndDate: (state, payload) => state.template.ending_at = payload,
            updateLocation: (state, payload) => state.template.location_id = payload,
            updateCustomer: (state, payload) => state.template.customer_id = payload,
            updateClassification: (state, payload) => state.template.classification_id = payload,
            updatePriority: (state, payload) => state.template.priority_id = payload,
            updateTeam: (state, payload) => state.template.team_id = payload,
            updateInterval: (state, payload) => state.template.schedule.interval = payload,
            updateTimeOfDay: (state, payload) => state.template.schedule.time_of_day = payload,
            updateDayOfWeek: (state, payload) => state.template.schedule.day_of_week = payload,
            updateDayOfMonth: (state, payload) => state.template.schedule.day_of_month = payload,
            updateWeekends: (state, payload) => state.template.schedule.weekends = payload,
            updateHolidays: (state, payload) => state.template.schedule.holidays = payload,
            updateFirstRun: (state, payload) => state.template.first_run = payload,
            updateName: (state, payload) => state.template.name = payload,
            updateStatus: (state, payload) => state.template.status = payload,
            updateOpeningCutoffTime: (state, payload) => state.template.opening_cutoff_time = payload,
        }
    }
}