import Location from '../models/Location'
import { location as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    location: new Location(),
    location_types: [],
    overview: {
      total_equipment: 0,
      work_orders_in_progress: 0,
      total_working_staff: 0,
      recent_checklists: []
    },
  },

  mutations: {
    ...Location.mutations(),
    setLocationTypes: (state, payload) => state.location_types = payload,
    setOverview: (state, payload) => state.overview = payload,
  },

  actions: {
    loadLocationTypes: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadLocationTypes(({ data }) => {
        commit('setLocationTypes', data)
        resolve()
      }, reject)
    }),
    load: ({ commit }, locationId) => new Promise((resolve, reject) => {
      backend.load(locationId, ({ data }) => {
        commit('setLocation', data)
        resolve()
      }, reject)
    }),
    create: ({ state }) => new Promise((resolve, reject) => {
      const location = { ...state.location }
      location.branches = location.branches.map(branch => branch.uuid)
      backend.create(location, resolve, reject)
    }),
    update: ({ state }) => new Promise((resolve, reject) => {
      const location = { ...state.location }
      location.branches = location.branches.map(branch => branch.uuid)
      backend.update(location, resolve, reject)
    }),
    deleteLocation: ({ state }) => new Promise((resolve, reject) => {
      backend.deleteLocation(state.location.uuid, resolve, reject)
    }),
    loadOverview: ({ commit }, locationId) => new Promise((resolve, reject) => {
      backend.loadOverview(locationId, ({ data }) => {
        commit('setOverview', data)
        resolve()
      }, reject)
    })
  },

  getters: {
    location: state => state.location,
    location_types: state => state.location_types,
    overview: state => state.overview,
  }

}