import Vue from 'vue'
import './icons'
import '@/pages/checkpoint/install'

Vue.component('google-address', () => import('./forms/google-address/GoogleAddress.vue'))
Vue.component('tab-link', () => import('./navigation/TabLink.vue'))
Vue.component('no-items-to-display', () => import('./utilities/NoItemsToDisplay.vue'))
Vue.component('page-heading', () => import('./utilities/PageHeading.vue'))
Vue.component('outcome-tag', () => import('./utilities/OutcomeTag.vue'))
Vue.component('download-button', () => import('./buttons/DownloadButton.vue'))
Vue.component('circular-progress-bar', () => import('./utilities/CircularProgressBar.vue'))
Vue.component('priority-indicator', () => import('./utilities/PriorityIndicator.vue'))
Vue.component('physical-address', () => import('./forms/Address.vue'))
Vue.component('inspectable-name', () => import('./utilities/Inspectable.vue'))
Vue.component('checklist-images-count', () => import('./utilities/ChecklistImagesCount.vue'))
Vue.component('checklist-videos-count', () => import('./utilities/ChecklistVideosCount.vue'))
Vue.component('calendar', () => import('./calendar/Calendar.vue'))
Vue.component('time-input', () => import('./forms/TimeInput.vue'))
Vue.component('difference-tag', () => import('./elements/DifferenceTag.vue'))
Vue.component('index-info', () => import('./elements/IndexInfo.vue'))