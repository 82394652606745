import findIndex from 'lodash/findIndex'

export default class Equipment {

    constructor(equipment = {}) {
        this.id = equipment.id || null
        this.uuid = equipment.uuid || ''
        this.name = equipment.name || ''
        this.properties = equipment.properties || {}
        this.classification = equipment.classification || {}
        this.classification_uuid = equipment.classification_uuid || ''
        this.user = equipment.user || {}
        this.user_id = equipment.user_id || ''
        this.user_uuid = equipment.user_uuid || ''
        this.location_uuid = equipment.location_uuid || ''
        this.location = equipment.location || {
            customer: {}
        }
        this.code = equipment.code || {
            value: ''
        }
        this.updated_at = equipment.updated_at || ''
        this.status = equipment.status || ''
    }

    static mutations() {
        return {
            setEquipment: (state, payload) => state.equipment = new Equipment(payload),
            clearEquipment: (state) => state.equipment = new Equipment(),
            updateName: (state, payload) => state.equipment.name = payload,
            updateClassification: (state, payload) => {
                state.equipment.classification_uuid = payload
                state.equipment.properties = []
            },
            updateUser: (state, payload) => state.equipment.user_uuid = payload,
            updateLocation: (state, payload) => state.equipment.location_uuid = payload,
            updateCodeValue: (state, payload) => state.equipment.code.value = payload,
            updateStatus: (state, payload) => state.equipment.status = payload,
            updatePropertyValue: (state, payload) => {
                const index = findIndex(state.equipment.properties, property => property.uuid === payload.uuid)
                if(index === -1) {
                    state.equipment.properties.push(payload)
                } else {
                    const property = state.equipment.properties[index]
                    property.value = payload.value
                    state.equipment.properties.splice(index, 1, property)
                }
                
            }
        }
    }

}