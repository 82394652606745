export default class WorkOrderClassification
{

  constructor(work_order_classification = {}) {
    this.id = work_order_classification.id || null
    this.uuid = work_order_classification.uuid || null
    this.title = work_order_classification.title || ''
    this.include_all_checklists = work_order_classification.include_all_checklists || false
    this.internal = work_order_classification.internal || false
    this.description = work_order_classification.description || ''
    this.segmented_teams = work_order_classification.segmented_teams || []
    this.work_orders_count = work_order_classification.work_orders_count || 0
    this.configuration = work_order_classification.configuration || {
      branch: true,
      team_name: true,
      team_lead: true,
      dates: true,
    }
  }

  static mutations() {
    return {
      clearClassification: (state) => state.work_order_classification = new WorkOrderClassification(),
      setClassification: (state, payload) => state.work_order_classification = new WorkOrderClassification(payload),
      updateTitle: (state, payload) => state.work_order_classification.title = payload,
      updateDescription: (state, payload) => state.work_order_classification.description = payload,
      toggleBranch: (state) => state.work_order_classification.configuration.branch = !state.work_order_classification.configuration.branch,
      toggleTeamName: (state) => state.work_order_classification.configuration.team_name = !state.work_order_classification.configuration.team_name,
      toggleTeamLead: (state) => state.work_order_classification.configuration.team_lead = !state.work_order_classification.configuration.team_lead,
      toggleDates: (state) => state.work_order_classification.configuration.dates = !state.work_order_classification.configuration.dates,
      toggleAllChecklists: (state) => state.work_order_classification.include_all_checklists = !state.work_order_classification.include_all_checklists,
      toggleInternal: (state) => state.work_order_classification.internal = !state.work_order_classification.internal,
      updateSegementedTeams: (state, payload) => state.work_order_classificationsegmented_teams = payload,
    }
  }

}