import { inspection as backend } from '@/api'
import Inspection from '../models/Inspection'

export default {

    namespaced: true,

    state: {
        checklist: new Inspection()
    },

    mutations: {
        ...Inspection.mutations()
    },

    actions: {
        load: ({commit}, checklistId) => new Promise((resolve, reject) => {
            backend.load(checklistId, ({data}) => {
                commit('setChecklist', data)
                resolve()
            }, reject)
        }),
        deleteChecklist: (_, checklistId) => new Promise((resolve, reject) => {
            backend.deleteChecklist(checklistId, resolve, reject)
        })
    },

    getters: {
        checklist: state => state.checklist
    }

}