import store from '@/store'

let guard = () => new Promise((resolve, reject) => {
    if (store.getters['auth/user'].id === store.getters['billing/subscription'].account.user_id) {
        resolve()
    } else {
        reject({
            name: 'dashboard',
        })
    }
})

export default {
    name: 'is-account-holder',
    run: guard
}
