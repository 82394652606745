import Checkpoint from '../models/Checkpoint'
import { checkpoint as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    checkpoint: new Checkpoint,
    checkpoint_types: [],
    comment_configurations: [
      { value: 'optional', label: 'Optional' },
      { value: 'required', label: 'Always Required' },
      { value: 'required_when_faulty', label: 'Only Required When Faulty' },
    ],
    image_configurations: [
      { value: 'optional', label: 'Optional' },
      { value: 'required', label: 'Always Required' },
      { value: 'required_when_faulty', label: 'Only Required When Faulty' },
    ]
  },

  mutations: {
    ...Checkpoint.mutations(),
    setCheckpointTypes: (state, payload) => state.checkpoint_types = payload
  },

  actions: {
    create: ({state}) => new Promise((resolve, reject) => {
      const checkpoint = { ...state.checkpoint }
      checkpoint.checklists = checkpoint.checklists.map((checklist) => checklist.id)
      return backend.create(checkpoint, ({data}) => {
        resolve(data)
      }, reject)
    }),
    load: ({commit}, checkpointId) => new Promise((resolve, reject) => {
      backend.load(checkpointId, ({data}) => {
        commit('setCheckpoint', data)
        resolve()
      }, reject)
    }),
    update: ({state}) => new Promise((resolve, reject) => {
      let checkpoint = {...state.checkpoint}
      checkpoint.checklists = checkpoint.checklists.map(checklist => checklist.id)
      backend.update(checkpoint, resolve, reject)
    }),
    loadTypes: ({commit}) => new Promise((resolve, reject) => {
      backend.loadTypes(({data}) => {
        commit('setCheckpointTypes', data)
        resolve()
      }, reject)
    })
  },

  getters: {
    checkpoint: state => state.checkpoint,
    checkpoint_types: state => state.checkpoint_types,
    comment_configurations: state => state.comment_configurations,
    image_configurations: state => state.image_configurations,
  }

}