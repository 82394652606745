import store from '@/store'

let guard = () => new Promise((resolve, reject) => {
    if (store.getters['auth/account_is_setup']) {
        resolve()
    } else {
        reject({
            name: 'setup-wizard',
        })
    }
})

export default {
    name: 'is-setup',
    run: guard
}
