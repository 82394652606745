export default class ChecklistClassification {
    constructor(classification = {}) {
        this.id = classification.id || null
        this.uuid = classification.uuid || ''
        this.name = classification.name || ''
        this.checklists_count = classification.checklists_count || 0
        this.segmented_teams = classification.segmented_teams || []
        this.configuration = classification.configuration || {
            branch: true,
            team_name: true,
            team_lead: true,
            dates: true,
        }
    }

    static mutations() {
        return {
            setChecklistClassification: (state, payload) => state.checklist_classification = new ChecklistClassification(payload),
            clearChecklistClassification: (state) => state.checklist_classification = new ChecklistClassification(),
            updateName: (state, payload) => state.checklist_classification.name = payload,
            toggleBranch: (state) => state.checklist_classification.configuration.branch = !state.checklist_classification.configuration.branch,
            toggleTeamName: (state) => state.checklist_classification.configuration.team_name = !state.checklist_classification.configuration.team_name,
            toggleTeamLead: (state) => state.checklist_classification.configuration.team_lead = !state.checklist_classification.configuration.team_lead,
            toggleDates: (state) => state.checklist_classification.configuration.dates = !state.checklist_classification.configuration.dates,
            updateSegmentedTeams: (state, payload) => state.checklist_classification.segmented_teams = payload,
        }
    }
}