import Checklist from '../models/Checklist'
import { checklist as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    checklist: new Checklist(),
    overview: {
      total_performed: 0,
      total_failures: 0,
      total_in_progress: 0,
      total_passed: 0,
      average_run_time: '',
      total_run_time: '',
      checklist_graph: [],
    },
    types: [
      { value: 'equipment', label: 'Equipment Based' },
      { value: 'locations', label: 'Location Based' },
    ]
  },

  mutations: {
    ...Checklist.mutations(),
    setOverview: (state, payload) => state.overview = payload
  },

  actions: {
    create: ({ state }) => new Promise((resolve, reject) => {
      const checklist = { ...state.checklist }
      checklist.equipment_classifications = checklist.equipment_classifications.map(classification => classification.id)
      checklist.checkpoints = checklist.checkpoints.map(checkpoint => checkpoint.id)
      checklist.segmented_teams = checklist.segmented_teams.map(team => team.uuid)
      backend.create(checklist, ({data}) => {
        resolve(data)
      }, reject)
    }),
    update: ({ state }) => new Promise((resolve, reject) => {
      const checklist = {...state.checklist}
      checklist.equipment_classifications = checklist.equipment_classifications.map(classification => {
        return {
          id: classification.id,
          interval: classification.pivot.interval
        }
      })
      checklist.segmented_teams = checklist.segmented_teams.map(team => team.uuid)
      backend.update(checklist, resolve, reject)
    }),
    load: ({ commit }, checklistId) => new Promise((resolve, reject) => {
      backend.load(checklistId, ({data}) => {
        commit('setChecklist', data)
        resolve()
      }, reject)
    }),
    loadOverview: ({ commit }, checklistId) => new Promise((resolve, reject) => {
      backend.loadOverview(checklistId, ({data}) => {
        commit('setOverview', data)
        resolve()
      }, reject)
    }),
    addCheckpoint: ({ state }, checkpoint) => new Promise((resolve, reject) => {
      backend.addCheckpoint({
        checklist_id: state.checklist.uuid,
        checkpoint_id: checkpoint.id,
      }, resolve, reject)
    }),
    removeCheckpoint: ({ state }, checkpoint) => new Promise((resolve, reject) => {
      backend.removeCheckpoint({
        checklist_id: state.checklist.uuid,
        checkpoint_id: checkpoint.id,
      }, resolve, reject)
    }),
    updateCheckpointSort: ({ state }) => new Promise((resolve, reject) => {
      const checklist = {...state.checklist}
      backend.updateCheckpointSort({
        checklist_id: checklist.uuid,
        checkpoints: checklist.checkpoints.map(checkpoint => checkpoint.id),
      }, resolve, reject)
    }),
    copy: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.copy(state.checklist.uuid, ({data}) => {
        commit('setChecklist', data)
        resolve(data)
      }, reject)
    }),
    deleteChecklist: ({ state }) => new Promise((resolve, reject) => {
      backend.deleteChecklist(state.checklist.uuid, resolve, reject)
    })
  },

  getters: {
    checklist: state => state.checklist,
    overview: state => state.overview,
    checklist_types: state => state.types,
  }

}