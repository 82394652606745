import { Route, RouteGroup } from '@billow/easy-route'

export default RouteGroup({

  middleware: ['guest'],

  routes: [
    Route('/signin', 'signin', () => import('@/pages/auth/Signin.vue')),
    Route('/signup', 'signup', () => import('@/pages/auth/Signup.vue')),
    Route('/forgot-password', 'forgot-password', () => import('@/pages/auth/ForgotPassword.vue')),
    Route('/reset-password', 'reset-password', () => import('@/pages/auth/ResetPassword.vue')),
  ]

})