export default class Inspection 
{
    constructor(inspection = {}) {
        this.id = inspection.id || null
        this.uuid = inspection.uuid || ''
        this.answers = inspection.answers || []
        this.checklist_id = inspection.checklist_id || ''
        this.checklist_uuid = inspection.checklist_uuid || ''
        this.checklist = inspection.checklist || {}
        this.started_at = inspection.started_at || ''
        this.closed_off_at = inspection.closed_off_at || ''
        this.created_at = inspection.created_at || ''
        this.updated_at = inspection.updated_at || ''
        this.status = inspection.status || ''
        this.notes = inspection.notes || ''
        this.work_order = inspection.work_order || {}
        this.work_order_uuid = inspection.work_order_uuid || ''
        this.user = inspection.user || {}
        this.geo_location = inspection.geo_location || {}
        this.inspectable = inspection.inspectable || {}
        this.inspectable_id = inspection.inspectable_id || ''
        this.inspectable_uuid = inspection.inspectable_uuid || ''
        this.area = inspection.area || {}
    }

    static mutations() {
        return {
            setChecklist: (state, payload) => state.checklist = new Inspection(payload),
            clear: (state) => state.checklist = new Inspection(),
        }
    }
}