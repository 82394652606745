import Vue from 'vue'
import store from './store'
import router from './router'
import { boot, startVue } from './boostrap'
import App from './App.vue'

Vue.config.productionTip = false

Promise.all([
  boot({ store, router }),
]).then(async () => {
  await store.dispatch('auth/loadAuthUser'),
  await store.dispatch('billing/loadSubscription')
}).finally(() => {
  startVue({
    store, router, App
  })
})
