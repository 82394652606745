import WorkOrder from '../models/WorkOrder'
import { workOrder as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    workOrder: new WorkOrder
  },

  mutations: {
    ...WorkOrder.mutations()
  },

  actions: {
    create: ({ state }) => new Promise((resolve, reject) => backend.create(state.workOrder, resolve, reject)),
    save: ({ state }) => new Promise((resolve, reject) => backend.save(state.workOrder, resolve, reject)),
    delete: ({ state }) => new Promise((resolve, reject) => backend.delete(state.workOrder.uuid, resolve, reject)),
    load: ({ commit }, workOrderId) => new Promise((resolve, reject) => backend.load(workOrderId, ({data}) => {
      commit('setWorkOrder', data)
      resolve()
    }, reject)),
    populateFromTemplate: ({ commit }, payload) => new Promise((resolve, reject) => {
      try {
        commit('populateFromTemplate', payload)
        resolve()
      } catch(e) {
        reject(e)
      }
    })
  },

  getters: {
    workOrder: state => state.workOrder
  }

}