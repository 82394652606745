import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faBell, faCalendar, faCheckCircle, faCircle, faClock, faCopy, faCreditCard, faEdit, faFileExcel, faImages, faLifeRing, faNoteSticky, faObjectGroup, faSave, faSquare, faSquareCheck, faStar, faThumbsDown, faThumbsUp, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faEye, faEyeSlash, faArrowRight, faArrowRightFromBracket, faBuildingFlag, faCameraAlt, faChevronLeft, faChevronRight, faCircleNotch, faClipboardList, faCog, faDownload, faHelmetSafety, faHouseChimneyCrack, faIndustry, faInfoCircle, faListCheck, faListOl, faMapLocationDot, faPeopleArrows, faPeopleGroup, faPerson, faPlus, faSitemap, faSpinner, faStreetView, faTimes, faUser, faInfinity, faTemperatureEmpty, faTemperatureHalf, faTemperatureFull, faQrcode, faHouse, faHashtag, faChevronDown, faStopwatch, faBarcode, faChevronUp, faChartLine, faChartSimple, faAngleLeft, faAngleRight, faAngleDoubleRight, faAngleDoubleLeft, faBolt, faWaveSquare, faUpload, faChartGantt, faSliders, faTrash, faArrowLeft, faCheck, faSearch, faShield, faGripVertical, faFilm, faExclamationTriangle, faGrip, faRotate, faCogs, faWrench } from '@fortawesome/free-solid-svg-icons'

library.add(
  faSave,
  faCreditCard,
  faDownload,
  faSpinner,
  faUser,
  faCog,
  faBuildingFlag,
  faLifeRing,
  faArrowRightFromBracket,
  faIndustry,
  faPerson,
  faCameraAlt,
  faThumbsUp,
  faThumbsDown,
  faCircleNotch,
  faInfoCircle,
  faTimesCircle,
  faTimes,
  faEyeSlash,
  faEye,
  faCheckCircle,
  faListCheck,
  faHelmetSafety,
  faMapLocationDot,
  faPeopleGroup,
  faBuildingFlag,
  faPeopleArrows,
  faStreetView,
  faObjectGroup,
  faClipboardList,
  faListOl,
  faHouseChimneyCrack,
  faArrowRight,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faPlus,
  faSitemap,
  faUser,
  faInfinity,
  faImages,
  faTemperatureEmpty,
  faTemperatureHalf,
  faTemperatureFull,
  faQrcode,
  faHouse,
  faHashtag,
  faStopwatch,
  faBarcode,
  faChevronUp,
  faChartLine,
  faChartSimple,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faBolt,
  faCircle,
  faCalendar,
  faWaveSquare,
  faUpload,
  faBell,
  faChartGantt,
  faEdit,
  faSliders,
  faFileExcel,
  faCopy,
  faTrash,
  faArrowLeft,
  faCheck,
  faStar,
  faSearch,
  faShield,
  faNoteSticky,
  faGripVertical,
  faFilm,
  faSquareCheck,
  faSquare,
  faExclamationTriangle,
  faGrip,
  faInfinity,
  faRotate,
  faCogs,
  faClock,
  faWrench,
  faRotate
)

dom.watch()