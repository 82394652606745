<template>
<div class="main-window" :style="{
  'height': showNav ? 'calc(100vh - $navbar-height)' : '100vh'
}">
  <slot />
</div>
</template>
<script>

export default {

  props: {
    showNav: Boolean
  }

}

</script>