import Vue from 'vue'

Vue.component('checkpoint-options-preview', () => import('./preview/partials/Options.vue'))
Vue.component('checkpoint-date-preview', () => import('./preview/partials/DatePicker.vue'))
Vue.component('checkpoint-number-preview', () => import('./preview/partials/Number.vue'))
Vue.component('checkpoint-checkbox-preview', () => import('./preview/partials/Checkbox.vue'))

Vue.component('checkpoint-options-builder', () => import('./builder/partials/Options.vue'))
Vue.component('checkpoint-date-builder', () => import('./builder/partials/DatePicker.vue'))
Vue.component('checkpoint-number-builder', () => import('./builder/partials/Number.vue'))
Vue.component('checkpoint-checkbox-builder', () => import('./builder/partials/Checkbox.vue'))
