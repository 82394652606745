import { Route, RouteGroup } from '@billow/easy-route'

export default RouteGroup({

    middleware: ['authenticated'],

    routes: [
        Route('/setup', 'setup-wizard', () => import('@/pages/account/setup/SetupWizard.vue'))
    ]

})