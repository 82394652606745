<template>
<aside class="menu side-menu" :class="{
  'is-open': isOpen
}">
  <!-- <div class="toggler is-hoverable" @click="toggleMinimize">
    <icon :icon="isOpen ? 'chevron-left' : 'chevron-right'"/>
  </div> -->
  <ul class="menu-list">
    <li>
      <router-link class="is-flex is-align-items-center" exact :to="{ name: 'dashboard' }">
        <icon icon="house-chimney-crack" class="mr-1"/>
        <span class="item-name" v-show="isOpen">Dashboard</span>
      </router-link>
    </li>
  </ul>
  <p v-show="isOpen" class="menu-label">
    Operations
  </p>
  <ul class="menu-list">
    <li>
      <router-link class="is-flex is-align-items-center" exact :to="{ name: 'work-orders' }">
        <icon icon="helmet-safety" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Work Orders</span>
      </router-link>
    </li>
    <!-- <li>
      <router-link exact :to="{ name: 'projects' }">
        Projects
      </router-link>
    </li> -->
    <li>
      <router-link class="is-flex is-align-items-center" exact :to="{ name: 'checklists' }">
        <icon icon="list-check" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Checklists</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" exact :to="{ name: 'equipment' }">
        <icon icon="qrcode" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Equipment</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'customers' }">
        <icon icon="people-group" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Customers</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'locations' }">
        <icon icon="map-location-dot" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Locations</span>
      </router-link>
    </li>
  </ul>
  <p v-show="isOpen" v-if="!isPassive" class="menu-label">
    Administration
  </p>
  <ul v-if="!isPassive" class="menu-list">
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'branches' }">
        <icon icon="building-flag" class="mr-1"/>
        <span class="item-name" v-show="isOpen">Branches</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'teams' }">
        <icon icon="people-arrows" class="mr-1"/>
        <span class="item-name" v-show="isOpen">Teams</span>
      </router-link>
    </li>
    <li v-if="$root.isAdmin()">
      <router-link class="is-flex is-align-items-center" :to="{ name: 'users' }">
        <icon icon="street-view" class="mr-1"/>
        <span class="item-name" v-show="isOpen">Users</span>
      </router-link>
    </li>
  </ul>
  <p v-if="!isPassive" v-show="isOpen" class="menu-label">
    Setup
  </p>
  <ul v-if="!isPassive" class="menu-list">
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'classifications' }">
        <icon icon="object-group" type="far" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Classifications</span>
      </router-link>
      <ul v-show="isOpen">
        <li>
          <router-link class="is-flex is-align-items-center" :to="{ name: 'checklist-classifications' }">
            <span class="item-name">Checklists</span>
          </router-link>
        </li>
        <li>
          <router-link class="is-flex is-align-items-center" :to="{ name: 'equipment-classifications' }">
            <span class="item-name">Equipment</span>
          </router-link>
        </li>
        <li>
          <router-link class="is-flex is-align-items-center" :to="{ name: 'work-order-classifications' }">
            <span class="item-name">Work Orders</span>
          </router-link>
        </li>
      </ul>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'checklist-templates' }">
        <icon icon="clipboard-list" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Checklist Templates</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'checkpoints' }">
        <icon icon="list-ol" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Checkpoints</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'work-order-templates' }">
        <icon icon="helmet-safety" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Work Order Templates</span>
      </router-link>
    </li>
    <li>
      <router-link class="is-flex is-align-items-center" :to="{ name: 'reports' }">
        <icon icon="chart-gantt" class="mr-1"/>
        <span class="item-name"  v-show="isOpen">Reporting <span class="tag is-warning is-small">Beta</span></span>
      </router-link>
    </li>
  </ul>
</aside>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  
  data: () => ({
    isOpen: true
  }),

  methods: {
    toggleMinimize() {
      this.isOpen = !this.isOpen
      this.$emit('minimize')
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    isPassive() {
      return this.user.is_passive
    }
  }

}
</script>