import { Route, RouteGroup } from '@billow/easy-route'

export default RouteGroup({

  middleware: ['authenticated', 'is-setup'],

  routes: [
    // Common
    Route('/', 'dashboard', () => import('@/pages/dashboard/Dashboard.vue')),
    Route('/profile', 'profile', () => import('@/pages/user/EditProfile.vue')),
    Route('/notifications', 'notifications', () => import('@/pages/notification/Index.vue')),

    // Billing and Support
    Route('/verify-card', 'verify-card', () => import('@/pages/billing/VerifyNewCard.vue')),
    Route('/complete-subscription', 'complete-subscription', () => import('@/pages/billing/CompleteSubscription.vue')),
    Route('/plans', 'plans', () => import('@/pages/billing/Plans.vue')),
    Route('/support', 'support', () => import('@/pages/support/Tickets.vue')),
    Route('/support-request', 'new-support-request', () => import('@/pages/support/NewTicket.vue')),
    Route('/support/:ticket', 'support-request', () => import('@/pages/support/Manager.vue')),

    // Accounts
    RouteGroup({
      middleware: ['is-account-holder'],
      parent: Route('/account', '', () => import('@/pages/account/Account.vue')),
      routes: [
        Route('/', 'account', () => import('@/pages/account/Edit.vue')),
        Route('billing', 'billing', () => import('@/pages/account/Billing.vue')),
        Route('branding', 'branding', () => import('@/pages/account/Branding.vue')),
      ]
    }),

    // Users
    Route('/users', 'users', () => import('@/pages/user/Index.vue')),
    Route('/user', 'create-user', () => import('@/pages/user/Create.vue')),
    RouteGroup({
      parent: Route('/users/:user', '', () => import('@/pages/user/Manager.vue')),
      routes: [
        Route('/', 'user-manager', () => import('@/pages/user/manager/Overview.vue')),
        Route('work-orders', 'user-work-orders', () => import('@/pages/user/manager/WorkOrders.vue')),
        Route('checklists', 'user-checklists', () => import('@/pages/user/manager/Checklists.vue')),
        Route('attachments', 'user-attachments', () => import('@/pages/user/manager/Attachments.vue')),
        Route('edit', 'edit-user', () => import('@/pages/user/manager/Edit.vue')),
      ]
    }),

    // Branches
    Route('/branches', 'branches', () => import('@/pages/branch/Index.vue')),
    Route('/branch', 'create-branch', () => import('@/pages/branch/Create.vue')),
    RouteGroup({
      parent: Route('/branches/:branch', '', () => import('@/pages/branch/Manager.vue')),
      routes: [
        Route('/', 'branch-manager', () => import('@/pages/branch/Overview.vue')),
        Route('staff', 'branch-staff', () => import('@/pages/branch/Staff.vue')),
        Route('teams', 'branch-teams', () => import('@/pages/branch/Teams.vue')),
        Route('preferences', 'branch-preferences', () => import('@/pages/branch/Edit.vue')),
        Route('work-orders', 'branch-work-orders', () => import('@/pages/branch/WorkOrders.vue')),
      ]
    }),

    // Teams
    Route('/teams', 'teams', () => import('@/pages/team/Index.vue')),
    Route('/team', 'create-team', () => import('@/pages/team/Create.vue')),
    RouteGroup({
      parent: Route('/teams/:team', '', () => import('@/pages/team/Manager.vue')),
      routes: [
        Route('/', 'team-manager', () => import('@/pages/team/Overview.vue')),
        Route('work-orders', 'team-work-orders', () => import('@/pages/team/WorkOrders.vue')),
        Route('members', 'team-members', () => import('@/pages/team/Members.vue')),
        Route('preferences', 'team-preferences', () => import('@/pages/team/Edit.vue')),
      ]
    }),
    
    // Jobs
    Route('/work-orders', 'work-orders', () => import('@/pages/work-order/Index.vue')),
    Route('/word-order', 'create-work-order', () => import('@/pages/work-order/Create.vue')),

    RouteGroup({
      parent: Route('/work-orders/:workOrder', '', () => import('@/pages/work-order/manager/Manager.vue')),
      routes: [
        Route('/', 'work-order-manager', () => import('@/pages/work-order/manager/Overview.vue')),
        Route('edit', 'edit-work-order', () => import('@/pages/work-order/manager/Edit.vue')),
      ]
    }),

    // Projects
    Route('/projects', 'projects', () => import('@/pages/project/Index.vue')),

    // Checklists
    Route('/checklists', 'checklists', () => import('@/pages/checklist/Index.vue')),

    RouteGroup({
      parent: Route('/checklists/:checklist', '', () => import('@/pages/checklist/manager/Manager.vue')),
      routes: [
        Route('/', 'checklist-manager', () => import('@/pages/checklist/manager/Overview.vue')),
      ]
    }),
    
    Route('/classifications', 'classifications', () => import('@/pages/classification/Overview.vue')),

    // Job Categories
    Route('/work-order-classifications', 'work-order-classifications', () => import('@/pages/work-order-classification/Index.vue')),
    Route('/work-order-classification', 'create-work-order-classification', () => import('@/pages/work-order-classification/Create.vue')),
    Route('/work-order-classification/:workOrderClassification', 'edit-work-order-classification', () => import('@/pages/work-order-classification/Edit.vue')),
    
    // Checklist Templates
    Route('/checklist-templates', 'checklist-templates', () => import('@/pages/checklist-template/Index.vue')),
    Route('/checklist-template', 'create-checklist-template', () => import('@/pages/checklist-template/Create.vue')),
    RouteGroup({
      parent: Route('/checklist-templates/:checklistTemplate', '', () => import('@/pages/checklist-template/Manager.vue')),
      routes: [
        Route('/', 'checklist-template-manager', () => import('@/pages/checklist-template/Overview.vue')),
        Route('checkpoints', 'checklist-template-checkpoints', () => import('@/pages/checklist-template/Checkpoints.vue')),
        Route('checklists', 'checklist-template-checklists', () => import('@/pages/checklist-template/Checklists.vue')),
        Route('preferences', 'checklist-template-preferences', () => import('@/pages/checklist-template/Edit.vue')),
      ]
    }),

    // Checkpoint
    Route('/checkpoints', 'checkpoints', () => import('@/pages/checkpoint/Index.vue')),
    Route('/checkpoint', 'create-checkpoint', () => import('@/pages/checkpoint/Create.vue')),
    Route('/checkpoint/:checkpoint', 'edit-checkpoint', () => import('@/pages/checkpoint/Edit.vue')),
    
    // Customers
    Route('/customers', 'customers', () => import('@/pages/customer/Index.vue')),
    Route('/customer', 'create-customer', () => import('@/pages/customer/Create.vue')),
    RouteGroup({
      parent: Route('/customers/:customer', '', () => import('@/pages/customer/Manager.vue')),
      routes: [
        Route('/', 'customer-manager', () => import('@/pages/customer/Overview.vue')),
        Route('locations', 'customer-locations', () => import('@/pages/customer/Locations.vue')),
        Route('work-orders', 'customer-work-orders', () => import('@/pages/customer/WorkOrders.vue')),
        Route('projects', 'customer-projects', () => import('@/pages/customer/Projects.vue')),
        Route('preferences', 'customer-preferences', () => import('@/pages/customer/Edit.vue')),
        Route('equipment', 'customer-equipment', () => import('@/pages/customer/Equipment.vue')),
        Route('contacts', 'customer-contacts', () => import('@/pages/customer/Contacts.vue')),
        Route('contact', 'create-customer-contact', () => import('@/pages/customer/CreateContact.vue')),
        Route('contacts/:contact', 'edit-customer-contact', () => import('@/pages/customer/EditContact.vue')),
      ]
    }),

    // Locations
    Route('/locations', 'locations', () => import('@/pages/location/Index.vue')),
    Route('/location', 'create-location', () => import('@/pages/location/Create.vue')),

    RouteGroup({
      parent: Route('/locations/:location', '', () => import('@/pages/location/Manager.vue')),
      routes: [
        Route('/', 'location-manager', () => import('@/pages/location/Overview.vue')),
        Route('preferences', 'location-preferences', () => import('@/pages/location/Edit.vue')),
        Route('equipment', 'location-equipment', () => import('@/pages/location/Equipment.vue')),
        Route('contacts', 'location-contacts', () => import('@/pages/location/Contacts.vue')),
        Route('contact', 'create-location-contact', () => import('@/pages/location/CreateContact.vue')),
        Route('contact/:contact', 'edit-location-contact', () => import('@/pages/location/EditContact.vue')),
        Route('work-orders', 'location-work-orders', () => import('@/pages/location/WorkOrders.vue')),
        Route('areas', 'location-areas', () => import('@/pages/location/Areas.vue')),
        Route('area', 'create-location-area', () => import('@/pages/location/CreateArea.vue')),
        Route('area/:area', 'edit-location-area', () => import('@/pages/location/EditArea.vue')),
      ]
    }),
    
    // Equipment Classifications
    Route('/equipment-classifications', 'equipment-classifications', () => import('@/pages/equipment-classification/Index.vue')),
    Route('/equipment-classification', 'create-equipment-classification', () => import('@/pages/equipment-classification/Create.vue')),
    RouteGroup({
      parent: Route('/equipment-classifications/:equipmentClassification', '', () => import('@/pages/equipment-classification/Manager.vue')),
      routes: [
        Route('/', 'equipment-classification-manager', () => import('@/pages/equipment-classification/Overview.vue')),
        Route('equipment', 'equipment-classification-equipment', () => import('@/pages/equipment-classification/Equipment.vue')),
        Route('edit', 'edit-equipment-classification', () => import('@/pages/equipment-classification/Edit.vue')),
      ]
    }),
    // Checklist Classifications
    Route('/checklist-classifications', 'checklist-classifications', () => import('@/pages/checklist-classification/Index.vue')),
    Route('/checklist-classification', 'create-checklist-classification', () => import('@/pages/checklist-classification/Create.vue')),
    Route('/checklist-classification/:checklistClassification', 'edit-checklist-classification', () => import('@/pages/checklist-classification/Edit.vue')),

    // Equipment
    Route('/equipment', 'equipment', () => import('@/pages/equipment/Index.vue')),
    Route('/create-equipment', 'new-equipment', () => import('@/pages/equipment/Create.vue')),
    RouteGroup({
      parent: Route('/equipment/:equipment', '', () => import('@/pages/equipment/Manager.vue')),
      routes: [
        Route('/', 'equipment-manager', () => import('@/pages/equipment/Overview.vue')),
        Route('checklists', 'equipment-checklists', () => import('@/pages/equipment/Checklists.vue')),
        Route('edit', 'edit-equipment', () => import('@/pages/equipment/Edit.vue')),
        Route('schedule', 'equipment-checklist-schedule', () => import('@/pages/equipment/Schedule.vue')),
      ]
    }),

    // Alerts
    Route('/alerts', 'alerts', () => import('@/pages/alerts/Index.vue')),
    Route('/alert', 'create-alert', () => import('@/pages/alerts/Create.vue')),
    Route('/alert/:alert', 'edit-alert', () => import('@/pages/alerts/Edit.vue')),

    // Reporting
    Route('/reports', 'reports', () => import('@/pages/reporting/Index.vue')),
    Route('/report', 'create-report', () => import('@/pages/reporting/Create.vue')),

    RouteGroup({
      parent: Route('/reports/:report', '', () => import('@/pages/reporting/Manager.vue')),
      routes: [
        Route('/', 'report-manager', () => import('@/pages/reporting/Overview.vue')),
        Route('widgets', 'report-widgets', () => import('@/pages/reporting/widgets/Index.vue')),
        Route('widget', 'create-report-widget', () => import('@/pages/reporting/widgets/Create.vue')),
        Route('widget/:widget', 'edit-report-widget', () => import('@/pages/reporting/widgets/Edit.vue')),
        Route('edit', 'edit-report', () => import('@/pages/reporting/Edit.vue')),
      ]
    }),

    // Work Order Templates
    Route('/work-order-templates', 'work-order-templates', () => import('@/pages/work-order-template/Index.vue')),
    Route('/work-order-template', 'create-work-order-template', () => import('@/pages/work-order-template/Create.vue')),

    RouteGroup({
      parent: Route('/work-order-templates/:template', '', () => import('@/pages/work-order-template/manager/Manager.vue')),
      routes: [
        Route('/', 'work-order-template-manager', () => import('@/pages/work-order-template/manager/Overview.vue')),
        Route('schedules', 'work-order-template-schedules', () => import('@/pages/work-order-template/manager/Schedule.vue')),
        Route('edit', 'edit-work-order-template', () => import('@/pages/work-order-template/manager/Edit.vue')),
        Route('work-orders', 'work-order-template-work-orders', () => import('@/pages/work-order-template/manager/WorkOrders.vue')),
      ]
    }),
  ]

})