import store from '@/store'
import { services } from '@billow/js-helpers'

let guard = () => new Promise((resolve, reject) => {

  if (services.auth.token()) {
    resolve()
  } else {
    store.commit('auth/deleteToken')
    reject({
      name: 'signin',
    })
  }
})

export default {
  name: 'authenticated',
  run: guard
}
