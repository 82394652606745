export default class ReportWidget
{
    constructor(widget = {}) {
        this.id = widget.id || null
        this.title = widget.title || ''
        this.type = widget.type || ''
        this.intro = widget.intro || ''
        this.date_key = widget.date_key || ''
        this.columns = widget.columns || []
        this.show_title = widget.show_title || false
    }

    static mutations() {
        return {
            setReportWidget: (state, payload) => state.widget = new ReportWidget(payload),
            clearReportWidget: (state) => state.widget = new ReportWidget(),
            updateTitle: (state, payload) => state.widget.title = payload,
            updateIntro: (state, payload) => state.widget.intro = payload,
            updateType: (state, payload) => state.widget.type = payload,
            updateSelectedColumns: (state, payload) => state.widget.columns = payload,
            toggleShowTitle: (state) => state.widget.show_title = !state.widget.show_title,
            updateColumnColour: (state, payload) => {
                const index = state.widget.columns.indexOf(payload.column) 
                let column = state.widget.columns[index]
                column.colour = payload.colour
                state.widget.columns.splice(index, 1, column) 
            },
            removeColumn: (state, payload) => {
                const index = state.widget.columns.indexOf(payload) 
                state.widget.columns.splice(index, 1) 
            }
        }
    }
}