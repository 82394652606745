export default class EquipmentClassification {
    
    constructor(classification = {}) {
        this.id = classification.id || null
        this.uuid = classification.uuid || ''
        this.name = classification.name || ''
        this.meta = classification.meta || []
        this.segmented_teams = classification.segmented_teams || []
        this.checklists = classification.checklists || []
        this.equipment_count = classification.equipment_count || 0
    }

    static mutations() {
        return {
            setEquipmentClassification: (state, payload) => state.equipment_classification = new EquipmentClassification(payload),
            clearEquipmentClassification: (state) => state.equipment_classification = new EquipmentClassification(),
            updateName: (state, payload) => state.equipment_classification.name = payload,
            addChecklist: (state, payload) => {
                let checklist = payload
                if(!checklist.pivot) {
                    checklist.pivot = {
                        interval: 0
                    }
                }
                state.equipment_classification.checklists.push(checklist)
            },
            addMetaProperty: (state, payload) => state.equipment_classification.meta.push(payload),
            removeChecklistTemplate: (state, payload) => {
                const index = state.equipment_classification.checklists.indexOf(payload)
                state.equipment_classification.checklists.splice(index, 1)
            },
            removeMetaProperty: (state, payload) => {
                const index = state.equipment_classification.meta.indexOf(payload)
                state.equipment_classification.meta.splice(index, 1)
            },
            updateChecklistInterval: (state, payload) => {
                const index = state.equipment_classification.checklists.indexOf(payload.checklist)
                let checklist = state.equipment_classification.checklists[index]
                checklist.pivot.interval = Number(payload.interval)
                console.log(checklist.pivot.interval)
                state.equipment_classification.checklists.splice(index, 1, checklist)
            },
            updateSegmentedTeams: (state, payload) => state.equipment_classification.segmented_teams = payload
        }
    }
}