import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './modules/auth'
import global from './modules/global'
import billing from './modules/billing'
import support from './modules/support'
import user from './modules/user'
import branch from './modules/branch'
import team from './modules/team'
import customer from './modules/customer'
import location from './modules/location'
import workOrderClassification from './modules/work-order-classification'
import checklist from './modules/checklist'
import checkpoint from './modules/checkpoint'
import workOrder from './modules/work-order'
import inspection from './modules/inspection'
import equipmentClassification from './modules/equipment-classification'
import checklistClassification from './modules/checklist-classification'
import equipment from './modules/equipment'
import report from './modules/report'
import reportWidget from './modules/report-widget'
import workOrderTemplate from './modules/work-order-template'
import industry from './modules/industry'

export default new Vuex.Store({
  modules: {
    auth,
    global,
    billing,
    support,
    user,
    branch,
    team,
    customer,
    location,
    workOrderClassification,
    checklist,
    checkpoint,
    workOrder,
    inspection,
    equipmentClassification,
    checklistClassification,
    equipment,
    report,
    reportWidget,
    workOrderTemplate,
    industry
  }
})