import Equipment from '../models/Equipment'
import { equipment as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        equipment: new Equipment,
        overview: {
            latest_checklist: {},
            total_checklists: 0,
            failed_checklists: 0,
        },
        schedule: []
    },

    mutations: {
        ...Equipment.mutations(),
        setOverview: (state, payload) => state.overview = payload,
        setSchedule: (state, payload) => state.schedule = payload,
    },

    actions: {
        loadEquipment: ({commit}, equipmentId) => new Promise((resolve, reject) => {
            backend.loadEquipment(equipmentId, ({data}) => {
                commit('setEquipment', data)
                resolve()
            }, reject)
        }),
        loadOverview: ({commit}, equipment) => new Promise((resolve, reject) => {
            backend.loadOverview(equipment, ({data}) => {
                commit('setOverview', data)
                resolve()
            }, reject)
        }),
        loadSchedule: ({commit}, equipment) => new Promise((resolve, reject) => {
            backend.loadSchedule(equipment, ({data}) => {
                commit('setSchedule', data)
                resolve()
            }, reject)
        }),
        save: ({state}) => new Promise((resolve, reject) => {
            backend.save(state.equipment, resolve, reject)
        }),
        create: ({state}) => new Promise((resolve, reject) => {
            backend.create(state.equipment, resolve, reject)
        })
    },

    getters: {
        equipment: state => state.equipment,
        overview: state => state.overview,
        schedule: state => state.schedule,
    }

}