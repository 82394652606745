import EquipmentClassification from '../models/EquipmentClassification'
import { equipmentClassification as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        equipment_classification: new EquipmentClassification(),
        equipment_classification_list: [],
        overview: {
            pieces_of_equipment: 0,
            faulty_equipment: 0,
            operational_equipment: 0,
        }
    },

    mutations: {
        ...EquipmentClassification.mutations(),
        setList: (state, payload) => state.equipment_classification_list = payload,
        setOverview: (state, payload) => state.overview = payload,
    },

    actions: {
        create: ({state}) => new Promise((resolve, reject) => {
            const equipment_classification = { ...state.equipment_classification }
            equipment_classification.checklists = equipment_classification.checklists.map(checklist => {
                return {
                    uuid: checklist.uuid,
                    interval: checklist.pivot.interval
                }
            })
            equipment_classification.segmented_teams = equipment_classification.segmented_teams.map(team => team.uuid)
            backend.create(equipment_classification, ({data}) => {
                resolve(data)
            }, reject)
        }),
        update: ({state}) => new Promise((resolve, reject) => {
            const equipment_classification = {...state.equipment_classification}
            equipment_classification.checklists = equipment_classification.checklists.map(checklist => {
                return {
                    uuid: checklist.uuid,
                    interval: checklist.pivot.interval
                }
            })
            equipment_classification.segmented_teams = equipment_classification.segmented_teams.map(team => team.uuid)
            backend.update(equipment_classification, resolve, reject)
        }),
        load: ({commit}, classificationId) => new Promise((resolve, reject) => {
            backend.load(classificationId, ({data}) => {
                commit('setEquipmentClassification', data)
                resolve()
            }, reject)
        }),
        loadList: ({commit}, search) => new Promise((resolve, reject) => {
            backend.loadList(search, ({data}) => {
                commit('setList', data)
                resolve()
            }, reject)
        }),
        loadOverview: ({commit}, equipmentClassificationId) => new Promise((resolve, reject) => {
            backend.loadOverview(equipmentClassificationId, ({data}) => {
                commit('setOverview', data)
                resolve()
            }, reject)
        }),
        duplicate: ({state}) => new Promise((resolve, reject) => {
            return backend.duplicate(state.equipment_classification.uuid, ({data}) => {
                resolve(data)
            }, reject)
        }),
        deleteClassification: ({state}) => new Promise((resolve, reject) => {
            return backend.deleteClassification(state.equipment_classification.uuid, resolve, reject)
        })
    },

    getters: {
        equipment_classification: state => state.equipment_classification,
        equipment_classification_list: state => state.equipment_classification_list,
        equipment_classification_overview: state => state.overview,
    }

}