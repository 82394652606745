import WorkOrderTemplate from '../models/WorkOrderTemplate'
import { workOrderTemplate as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        template: new WorkOrderTemplate,
        overview: {
            total_work_orders: 0,
            total_schedules: 0
        }
    },

    mutations: {
        ...WorkOrderTemplate.mutations(),
        setOverview: (state, payload) => state.overview = payload
    },

    actions: {
        create: ({ state }) => new Promise((resolve, reject) => backend.create(state.template, resolve, reject)),
        save: ({ state }) => new Promise((resolve, reject) => backend.save(state.template, resolve, reject)),
        loadTemplate: ({ commit }, templateId) => new Promise((resolve, reject) => backend.load(templateId, ({ data }) => {
            commit('setWorkOrder', data)
            resolve()
        }, reject)),
        pause: ({ commit, state }) => new Promise((resolve, reject) => backend.pause(state.template.id, () => {
            commit('updateStatus', 'paused')
            resolve()
        }, reject)),
        resume: ({ commit, state }, next_run) => new Promise((resolve, reject) => backend.resume({
            id: state.template.id,
            ...next_run
        }, () => {
            commit('updateStatus', 'active')
            resolve()
        }, reject)),
        deleteTemplate: ({ state }) => new Promise((resolve, reject) => backend.deleteTemplate(state.template.id, () => {
            resolve()
        }, reject)),
        loadOverview: ({ commit }, templateId) => new Promise((resolve, reject) => backend.loadOverview(templateId, ({data}) => {
            commit('setOverview', data)
            resolve()
        }, reject)),
    },

    getters: {
        template: state => state.template,
        overview: state => state.overview,
    }

}