export default class Checklist
{

  constructor(checklist = {}) {
    this.id = checklist.id || null
    this.uuid = checklist.uuid || ''
    this.name = checklist.name || ''
    this.type = checklist.type || ''
    this.pass_percentage = checklist.pass_percentage || 0
    this.description = checklist.description || ''
    this.classification_id = checklist.classification_id || ''
    this.classification_uuid = checklist.classification_uuid || ''
    this.estimated_duration = checklist.estimated_duration || ''
    this.equipment_classifications = checklist.equipment_classifications || []
    this.checkpoints = checklist.checkpoints || []
    this.segmented_teams = checklist.segmented_teams || []
    this.updated_at = checklist.updated_at || ''
    this.created_at = checklist.created_at || ''
    this.inspections_count = checklist.inspections_count || 0
  }

  static mutations() {
    return {
      clearChecklist: (state) => state.checklist = new Checklist,
      setChecklist: (state, payload) => state.checklist = new Checklist(payload),
      updateName: (state, payload) => state.checklist.name = payload,
      updateType: (state, payload) => state.checklist.type = payload,
      updatePassPercentage: (state, payload) => state.checklist.pass_percentage = payload,
      updateDescription: (state, payload) => state.checklist.description = payload,
      updateClassification: (state, payload) => state.checklist.classification_uuid = payload,
      updateSegmentedTeams: (state, payload) => state.checklist.segmented_teams = payload,
      addEquipmentClassification: (state, payload) => {
        if(!payload.pivot) {
          payload = {
            ...payload,
            pivot: {
              interval: 0
            }
          }
        }
        state.checklist.equipment_classifications.push(payload)
      },
      updateEstimatedDuration: (state, payload) => state.checklist.estimated_duration = payload,
      removeEquipmentClassification: (state, payload) => {
        const index = state.checklist.equipment_classifications.indexOf(payload)
        state.checklist.equipment_classifications.splice(index, 1)
      },
      updateChecklistInterval: (state, payload) => {
        const index = state.checklist.equipment_classifications.indexOf(payload.classification)
        const classification = state.checklist.equipment_classifications[index]
        classification.pivot.interval = payload.interval
        state.checklist.equipment_classifications.splice(index, 1, classification)
      },
      addCheckpoint: (state, payload) => state.checklist.checkpoints.push(payload),
      removeCheckpoint: (state, payload) => {
        const index = state.checklist.checkpoints.indexOf(payload)
        state.checklist.checkpoints.splice(index, 1)
      },
      updateCheckpoints: (state, payload) => state.checklist.checkpoints = payload
    }
  }

}