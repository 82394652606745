import { industry as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        industries: []
    },

    mutations: {
        setIndustries: (state, payload) => state.industries = payload,
    },

    actions: {
        loadList: ({commit}) => new Promise((resolve, reject) => {
            backend.loadList(({data}) => {
                commit('setIndustries', data)
                resolve()
            }, reject)
        })
    },

    getters: {
        industry_list: state => state.industries,
    }

}