import Report from '../models/Report'
import { report as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        report: new Report,
        formats: [
            { value: 'xls', label: 'XLSX' },
            { value: 'pdf', label: 'PDF' },
        ]
    },

    mutations: {
        ...Report.mutations()
    },

    actions: {
        create: ({state}) => new Promise((resolve, reject) => {
            const report = {...state.report}
            report.customers = report.customers.map(customer => customer.id)
            report.locations = report.locations.map(location => location.id)
            report.checklists = report.checklists.map(checklist => checklist.id)
            report.formats = report.formats.map(format => format.value)
            return backend.create(report, resolve, reject)
        }),
        update: ({state}) => new Promise((resolve, reject) => {
            const report = {...state.report}
            report.customers = report.customers.map(customer => customer.id)
            report.locations = report.locations.map(location => location.id)
            report.checklists = report.checklists.map(checklist => checklist.id)
            report.formats = report.formats.map(format => format.value)
            return backend.update(report, resolve, reject)
        }),
        load: ({commit, state}, reportId) => new Promise((resolve, reject) => {
            backend.load(reportId, ({data}) => {
                let report = data
                data.columns.push({
                    name: 'status',
                    type: 'column',
                    attribute: 'status'
                })
                report.formats = state.formats.filter(format => report.formats.includes(format.value))
                commit('setReport', data)
                resolve()
            }, reject)
        }),
        createWidget: ({state, commit}, widget) => new Promise((resolve, reject) => {
            backend.createWidget({
                report_id: state.report.id,
                ...widget
            }, ({data}) => {
                commit('addWidget', data)
                resolve()
            }, reject)
        }),
        deleteWidget: ({commit}, widget) => new Promise((resolve, reject) => {
            backend.deleteWidget(widget.id, () => {
                commit('removeWidget', widget)
                resolve()
            }, reject)
        })
    },

    getters: {
        report: state => state.report,
        meta: state => state.report.meta,
        formats: state => state.formats,
    }

}