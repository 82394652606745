<template>
<div id="app" class="page-wrap" :style="{
    'grid-template-columns': showNav && sideBarIsOpen ? 'minmax(10px, 200px) minmax(10px, 3fr)' : '100%',
    'grid-template-rows': showNav && sideBarIsOpen ? 'min-content min-content 1fr min-content' : 'auto',
  }">
  <navbar v-if="showNav" class="page-header"/>
  <side-bar
    class="page-sidebar"
    v-if="showNav && sideBarIsOpen"
  />
  <main-window :show-nav="showNav" :style="{
    'grid-column': showNav ? '2 / 3' : ''
  }">
    <div class="app-window">
      <router-view />
      <div v-if="needsUpdate && showNav" class="version-updater content has-text-centered">
        <p>
          There is a newer version of Inspeckta Available.
        </p>
        <action-button @click="updateVersion" class="is-warning">Click to Update</action-button>
      </div>
    </div>
    <popups />
  </main-window>
  <!-- <footer class="page-footer">
    Footer
  </footer> -->
</div>
</template>
<script>
import Navbar from '@/layout/nav/Navbar.vue'
import Popups from './components/Popups.vue'
import { mapGetters } from 'vuex'
import SideBar from './layout/nav/SideBar.vue'
import MainWindow from './layout/main/MainWindow.vue'
var pjson = require('../package.json')

export default {

  components: {
    Navbar,
    Popups,
    SideBar,
    MainWindow
  },

  data: () => ({
    sidebarIsMinimized: false,
    windowWidth: 0
  }),

  created() {
    this.windowWidth = window.innerWidth
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },

  methods: {
    updateVersion() {
      location.reload()
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('global', [
      'current_version'
    ]),
    showNav() {
      return this.user.id && ['signin', 'signup', 'forgot-password', 'setup-wizard'].includes(this.$route.name) === false
    },
    localVersion() {
      return Number(pjson.version.replaceAll('.', ''))
    },
    serverVersion() {
      return Number(this.current_version.replaceAll('.', ''))
    },
    needsUpdate() {
      return this.localVersion < this.serverVersion
    },
    sideBarIsOpen() {
      return this.windowWidth > 1028
    }
  }
}
</script>

<style lang="scss">
@import './scss/app.scss';
</style>
