export default class Checkpoint
{

  constructor(checkpoint = {}) {
    this.id = checkpoint.id || null
    this.uuid = checkpoint.uuid || ''
    this.title = checkpoint.title || ''
    this.instruction = checkpoint.instruction || ''
    this.additional_information = checkpoint.additional_information || ''
    this.checkpoint_type_id = checkpoint.checkpoint_type_id || 3
    this.comment_configuration = checkpoint.comment_configuration || 'optional'
    this.image_configuration = checkpoint.image_configuration || 'optional'
    this.handler_payload = Checkpoint.parseHandlerPayload(checkpoint.handler_payload, this.checkpoint_type_id)
    this.checklists = checkpoint.checklists || []
    this.point_of_failure = checkpoint.point_of_failure || false
  }

  static mutations() {
    return {
      clearCheckpoint: (state) => state.checkpoint = new Checkpoint(),
      setCheckpoint: (state, payload) => {
        state.checkpoint = new Checkpoint(payload)
      },
      updateTitle: (state, payload) => state.checkpoint.title = payload,
      updateInstruction: (state, payload) => state.checkpoint.instruction = payload,
      updateAdditionalInformation: (state, payload) => state.checkpoint.additional_information = payload,
      updateType: (state, payload) => state.checkpoint.checkpoint_type_id = payload,
      updateCommentConfiguration: (state, payload) => state.checkpoint.comment_configuration = payload,
      updateImageConfiguration: (state, payload) => state.checkpoint.image_configuration = payload,
      setHandlerPayload: (state, payload) => state.checkpoint.handler_payload = payload,
      updateChecklists: (state, payload) => state.checkpoint.checklists = payload,
      togglePointOfFailure: (state) => {
        state.checkpoint.point_of_failure = !state.checkpoint.point_of_failure
      }
    }
  }

  static parseHandlerPayload(handlerPayload, checkpointTypeId) {
    switch(checkpointTypeId) {
      case 1: return new DatePickerCheckpoint(handlerPayload)
      case 2: return new NumberCheckpoint(handlerPayload)
      case 3: return new MultipleChoiceCheckpoint(handlerPayload)
      case 4: return new CheckboxCheckpoint(handlerPayload)
      default: return new MultipleChoiceCheckpoint()
    }
  }
}

export class MultipleChoiceCheckpoint {
  constructor(handler = {}) {
    this.ok_label = handler.ok_label || 'Ok',
    this.ok_value = 1
    this.failure_label = handler.failure_label || 'Faulty'
    this.failure_value = 0
    this.na_label = handler.na_label || 'N/A'
    this.na_value = -1
    this.with_na = handler.with_na
  }
}

export class CheckboxCheckpoint {
  constructor(handler = {}) {
    this.label = handler.label || 'Ok',
    this.fails_on = handler.fails_on || 'does_not_fail'
  }
}

export class DatePickerCheckpoint {
  constructor(handler = {}) {
    this.label = handler.label || 'Date'
  }
}

export class NumberCheckpoint {
  constructor(handler = {}) {
    this.label = handler.label || 'Your Input Label'
    this.min = handler.min || ''
    this.max = handler.max || ''
    this.units = handler.units || ''
    this.meta = handler.meta || {
      with_minimum: true,
      with_maximum: true,
      with_units: false
    }
  }
}