import ReportWidget from '../models/ReportWidget'
import { report as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        widget: new ReportWidget()
    },

    mutations: {
        ...ReportWidget.mutations()
    },

    actions: {
        loadWidget: ({commit}, widgetId) => new Promise((resolve, reject) => {
            backend.loadWidget(widgetId, ({data}) => {
                commit('setReportWidget', data)
                resolve()
            }, reject)
        }),
        updateWidget: ({state}) => new Promise((resolve, reject) => {
            backend.updateWidget(state.widget, resolve, reject)
        })
    },

    getters: {
        widget: state => state.widget
    }

}