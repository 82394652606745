import ChecklistClassification from '../models/ChecklistClassification'
import { checklistClassification as backend } from '@/api'

export default {

    namespaced: true,

    state: {
        checklist_classification: new ChecklistClassification()
    },

    mutations: {
        ...ChecklistClassification.mutations()
    },

    actions: {
        create: ({ state }) => new Promise((resolve, reject) => {
            const classification = { ...state.checklist_classification }
            classification.segmented_teams = classification.segmented_teams.map(team => team.uuid)
            backend.create(classification, ({data}) => {
                resolve(data)
            }, reject)
        }),
        load: ({ commit }, classificationId) => new Promise((resolve, reject) => {
            backend.load(classificationId, ({data}) => {
                commit('setChecklistClassification', data)
                resolve()
            }, reject)
        }),
        update: ({ state }) => new Promise((resolve, reject) => {
            const classification = { ...state.checklist_classification }
            classification.segmented_teams = classification.segmented_teams.map(team => team.uuid)
            backend.update(classification, resolve, reject)
        }),
        deleteClassification: ({ state }) => new Promise((resolve, reject) => {
            backend.deleteClassification(state.checklist_classification.uuid, resolve, reject)
        })
    },

    getters: {
        checklist_classification: state => state.checklist_classification
    }

}